function init_fancybox(context) {
	// fancybox
	$(".fancybox", context).fancybox({
		width : '70%',
		height : '100%',
		autoSize : false,
		fitToView : false
	});
	$('a[rel^="fancybox"]', context).fancybox();
}

function init_tooltips(context) {
	// tooltip
	$('[data-bs-toggle="tooltip"]', context).tooltip();
}

function init_selectpicker(context) {
    // selectpicer
    // selectpicer
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
        $('.selectpicker', context).selectpicker('mobile');
    } else {
        $('.selectpicker', context).selectpicker();
    }
    /*{
        'width':'100%',
    }).addClass('select-default').selectpicker('setStyle');*/

}

function init_components(context) {
	// init_fancybox(context);
	// init_tooltips(context);
	// init_selectpicker(context);
}

window.init_components = init_components;

function init_scrollup() {
	if ($('#scrollup').length) {
		$(document).on('click', '#scrollup', function(){
			$("html, body").animate({ scrollTop: 0 }, "slow");
		});
		function check_scrollup() {
			$('#scrollup').stop();
			if ($(window).scrollTop() > 200) {
				$('#scrollup').fadeIn();
			} else {
				$('#scrollup').fadeOut();
			}
		}
		check_scrollup();
		$(window).scroll(check_scrollup);
	}
}

function init_quantity_control() {
	// quantity-control ([-][?][+])
	$(document).on('click', 'div.quantity_control .plus, div.quantity_control .minus', function(e) {
		var $input = $(this).parents('.quantity_control').find('input');
		var val = parseInt($input.val()) || 1;
		if ($(this).is('.minus')) {
			val--;
		} else {
			val++;
		}
		var min_val = $input.data('min') !== undefined ? $input.data('min') : 1;
		var max_val = $input.data('max') !== undefined ? $input.data('max') : 999;
		if (val < min_val) val = min_val;
		if (val > max_val) val = max_val;
		$input.val(val);
	});
}

function init_ajaxnav() {
	// ajaxnav
	$(document).on('click', '[data-ajaxnav="true"]:not(form)', function(e){
		var url = this.href !== undefined ? this.href : $(this).data('ajaxnav-url');
        var callback = $(this).data('ajaxnav-callback');
		if (url) {
			e.preventDefault();
			ajaxnav({
				url: url,
				container: $(this).data('ajaxnav-container'), 
				template: $(this).data('ajaxnav-template'),
                append_history: $(this).data('ajaxnav-append_history'),
                update_address: $(this).data('ajaxnav-update_address'),
                callback: function(){
                    if (typeof window[callback] == 'function') {
                        window[callback]();
                    }
                }
			});
		}
	});
	
	$(document).on('submit', 'form[data-ajaxnav="true"]', function(e){
		var url = this.action ? this.action : ($(this).data('ajaxnav-url') ? $(this).data('ajaxnav-url') : '');
        var callback = $(this).data('ajaxnav-callback');
		e.preventDefault();
		ajaxnav({
			url: url, 
			method: this.method ? this.method.toUpperCase() : 'GET',
			data: $(this).serialize(), 
			container: $(this).data('ajaxnav-container'), 
			template: $(this).data('ajaxnav-template'),
            append_history: $(this).data('ajaxnav-append_history'),
            update_address: $(this).data('ajaxnav-update_address'),
            callback: function(){
                if (typeof window[callback] == 'function') {
                    window[callback]();
                }
            }
		});
		return false;
	});

	window.onpopstate = function(event) {
		if (event.state && event.state.container && event.state.template) {
			ajaxnav(window.location.href, event.state.container, event.state.template, false);
		} else {
			if (!document.location.hash) {
				$('#ajax_loader').show();
				window.location.reload();
			}
		}
	};
}

function init_list_collapse() {
	function list_collapse($list) {
		$list.toggleClass('open');
	}
	$(document).on('click', 'div.list-collapse > .title', function(e){
		e.preventDefault();
		list_collapse($(this).parent());
	});
	$(document).on('click', 'div.list-collapse-mobile > .title', function(e){
		if (get_vw() <= screen_sm_max) {
			e.preventDefault();
			list_collapse($(this).parent());
		}
	});
	$(document).on('click', 'div.list-collapse-desktop > .title', function(e){
		if (get_vw() >= screen_md_min) {
			e.preventDefault();
			list_collapse($(this).parent());
		}
	});
}

function init_list_dropdown() {
	function list_dropdown_click($list) {
		// jeigu click'as ivyko 100ms po hoverio, reikia uzdaryti drop-down'a
		if ($list.is('.hover') && ($list.data('hovertime') < (new Date()).getTime() - 100)) {
			$list.removeClass('hover');
		} else {
			$list.addClass('hover');
		}
	}
	function list_dropdown_enter($list) {
		$list.addClass('hover').data('hovertime', (new Date()).getTime());
        var submenu = $list.children('.submenu_list');
		
		var maxheight = $(window).height() - (submenu.offset().top - $(document).scrollTop()) - 20;
		submenu.find('> ul').css('max-height', maxheight);
		//submenu.find('> ul').niceScroll({autohidemode:false});

		if (submenu.length > 0) {
			var browser_width = $(document).outerWidth(true);
			var element_width = submenu.width();
			var offset_left = submenu.offset().left;
			var offset_right = browser_width - element_width - offset_left;
			if (offset_right > element_width) {
				submenu.css({'left': '0px', 'right': 'auto'});
			} else {
				submenu.css({'right': '0px', 'left': 'auto'});
			}
		}
	}
	function list_dropdown_leave($list) {
		var hovertime = $list.data('hovertime');
		var hover_delay = $list.data('hover-delay') ? $list.data('hover-delay') : 0;
		var $dropdown = $list;
		setTimeout(function(){
			// patikrinam, ar siuo metu dropdown'as tikrai neturetu buti rodomas
			if ($dropdown.data('hovertime') == hovertime) {
				$dropdown.removeClass('hover');
			}
		}, hover_delay);
	}

	// div.list-dropdown
    if (get_vw() > screen_sm_max) {
        $(document).on('click', 'div.list-dropdown > .title', function(){
            list_dropdown_click($(this).parent());
        });
        $(document).on('mouseenter', 'div.list-dropdown', function(){
            list_dropdown_enter($(this));
        });
        $(document).on('mouseleave', 'div.list-dropdown', function(){
            list_dropdown_leave($(this));
        });
	}

	// div.list-dropdown-mobile
	$(document).on('click', 'div.list-dropdown-mobile > .title', function(){
		if (get_vw() <= screen_sm_max) {
			list_dropdown_click($(this).parent());
		}
	});
	$(document).on('mouseenter', 'div.list-dropdown-mobile', function(){
		if (get_vw() <= screen_sm_max) {
			list_dropdown_enter($(this));
		}
	});
	$(document).on('mouseleave', 'div.list-dropdown-mobile', function(){
		if (get_vw() <= screen_sm_max) {
			list_dropdown_leave($(this));
		}
	});

	// div.list-dropdown-desktop
	$(document).on('click', 'div.list-dropdown-desktop > .title', function(){
		if (get_vw() >= screen_md_min) {
			list_dropdown_click($(this).parent());
		}
	});
	$(document).on('mouseenter', 'div.list-dropdown-desktop', function(){
		if (get_vw() >= screen_md_min) {
			list_dropdown_enter($(this));
		}
	});
	$(document).on('mouseleave', 'div.list-dropdown-desktop', function(){
		if (get_vw() >= screen_md_min) {
			list_dropdown_leave($(this));
		}
	});

    if (get_vw() >= screen_md_min) {
        $('.list-dropdown li.has_child').hover(
            function() {
                $(this).addClass('hover');

                var browser_width = $(document).outerWidth(true);
                var parent_width = $(this).width();
                var child = $(this).children('ul');
                var child_width = child.width();
                var offset_left = $(this).offset().left;
                var offset_right = browser_width - parent_width - offset_left;

                if (offset_right > child_width) {
                    child.css('left', $(this).width() + 'px');
                    child.css('right', 'auto');
                } else {
                    child.css('right', $(this).width() + 'px');
                    child.css('left', 'auto');
                }
            },
            function() {
                $(this).removeClass('hover');
            }
        );

    }
    $(document).on('mouseleave', '.list-dropdown li.hover', function() {
        if (get_vw() >= screen_md_min) {
            var parent = $(this).parent('li');
            parent.removeClass('hover');
        }
    });
}

function init_wishlist() {
	$(document).on('click', '.add_to_wishlist', function(e) {
		e.preventDefault();
		var el = $(this);
		if (el.data('id') == "") {
            alert ($alert_message);
		} else {
            $.ajax({
                url: '?display=content_types/wishlist/update_wishlist',
                data: {entity_id: el.data('id'),entity_name: el.data('entity')},
                method: 'post',
                dataType: 'json',
                success: function (json) {
					let w_elements = $('.add_to_wishlist[data-id="'+ el.data('id') +'"][data-entity="'+ el.data('entity') +'"]');

                    if (json.status > 0) {
						w_elements.addClass('active');
						w_elements.attr('title', w_elements.data('del-title'));
						w_elements.attr('data-bs-original-title', w_elements.data('del-title'));
                    } else if (json.status < 0) {
						w_elements.removeClass('active');
						w_elements.attr('title', w_elements.data('add-title'));
						w_elements.attr('data-bs-original-title', w_elements.data('add-title'));
                    }
                    update_wishlist_info();
                }
            });
        }
	});
}

$(function(){
	init_components(document);
	init_scrollup();
	init_quantity_control();
	init_ajaxnav();
	init_list_collapse();
	init_list_dropdown();
	init_wishlist();
});